import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import Quentin1 from "../../assets/quentin-webfont.woff"
import Quentin2 from "../../assets/quentin-webfont.woff2"
import GLECB1 from "../../assets/GLECB.woff"
import GLECB2 from "../../assets/GLECB.woff2"
import { initStore } from '../../redux/store';


import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Quentin";
    font-style: normal;
    font-display: swap;
    src: url(${Quentin1});
  }
  @font-face {
    font-family: "Quentin2";
    font-style: normal;
    font-display: swap;
    src: url(${Quentin2});
  }
  @font-face {
    font-family: "GLECB";
    font-style: normal;
    font-display: swap;
    src: url(${GLECB1});
  }
  @font-face {
    font-family: "GLECB2";
    font-style: normal;
    font-display: swap;
    src: url(${GLECB2});
  }

  body, html {
    padding: 0;
    margin: 0;
  }
`;

let client;

if (typeof window !== 'undefined') {
  client = new ApolloClient({
    cache: new InMemoryCache(),
    link: new createHttpLink({
      uri: `https://arpeje.myshopify.com/api/2021-04/graphql.json`,
      headers: {
        'X-Shopify-Storefront-Access-Token': '57c741f2c517aeda1049697d8ad822cc',
        'Accept': 'application/graphql'
      },
      fetch,
    }),
  });
}

const getGraphQLClient = () => client;

const Init = ({store}) => {
  useEffect(() => {
    store.dispatch({ type: "INIT" });
  })
  return <Helmet><meta name="description" content="yolo"/></Helmet>;
}

const wrapRootElement = ({ element }) => {
  const store = initStore();
  
  return (
    <Provider store={store}>
      <GlobalStyle />
      <Init store={store} />
      {element}
    </Provider>
  );
}

export {
  wrapRootElement,
  getGraphQLClient,
}