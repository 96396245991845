import { createStore, compose, applyMiddleware } from 'redux';
import {reducer, initialState} from './reducer';
import { install } from 'redux-loop';
import {createLogger} from 'redux-logger'

const logger = createLogger({
  collapsed: true
});

const enhancer = compose(
  install(),
  applyMiddleware(logger),
);

export const initStore = () => createStore(reducer, initialState, enhancer);