import { Cmd } from 'redux-loop';
import Client from 'shopify-buy';
import { getGraphQLClient } from '../components/layout/wrapper';
import gql from 'graphql-tag'
import { navigate } from 'gatsby'

export const INIT = "INIT";
export const INIT_SHOPIFY_CLIENT = "INIT_SHOPIFY_CLIENT";

export const initShopifyClientCmd = () => {
  const client = Client.buildClient({
    domain: 'arpeje.myshopify.com',
    storefrontAccessToken: '57c741f2c517aeda1049697d8ad822cc'
  });

  return Cmd.action({
    type: INIT_SHOPIFY_CLIENT,
    payload: client,
  })
};

export const INIT_USER = "INIT_USER";
export const initUserCmd = () => {
  const token = localStorage.getItem("userTokenValue");
  const expiration = localStorage.getItem("userTokenExpiration");
  if (token && new Date(expiration) >= new Date()) {
    return Cmd.action({
      type: INIT_USER,
      payload: {
        token,
        expiration: new Date(expiration)
      },
    });
  } else {
    return Cmd.none
  }
}

export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
const CUSTOMER_INFO = gql`
  query($customerAccessToken: String!) {
      customer(customerAccessToken: $customerAccessToken) {
          email
          firstName
          lastName
          phone
          defaultAddress {
              firstName
              lastName
              address1
              city
              zip
              country
          }
          orders(first: 10) {
              edges {
                  node {
                      name
                      totalPrice
                      processedAt
                      statusUrl
                      currencyCode
                      lineItems(first: 10) {
                          edges {
                              node {
                                  title
                                  quantity
                              }
                          }
                      }
                      shippingAddress {
                          address1
                          city
                          lastName
                          firstName
                          zip
                          country
                      }
                      subtotalPrice
                      totalPrice
                  }
              }
          }
          addresses(first: 10) {
              edges {
                  node {
                      address1
                      city
                      lastName
                      firstName
                      country
                      name
                      zip
                  }
              }
          }
      }
  }
  `

const successGetUser = ({ data }) => {
  return {
    type: GET_USER_SUCCESS,
    payload: {
      meta: {
        firstName: data.customer.firstName,
        lastName: data.customer.lastName,
        email: data.customer.email,
      },
      orders: data.customer.orders.edges || [],
      defaultAddress: data.customer.defaultAddress,
      addresses: data.customer.orders.addresses || [],
    }
  }
}

export const getUserCmd = (token) => {
  return Cmd.run(
    () => getGraphQLClient().query({ query: CUSTOMER_INFO, variables: { customerAccessToken: token } }),
    {
      successActionCreator: successGetUser,
      failActionCreator: result => ({ type: "ERROR" }),
    },
  )
}

///////// LOGIN

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_OK = "LOGIN_OK";

const CUSTOMER_LOGIN = gql`
mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
  customerAccessTokenCreate(input: $input) {
    customerAccessToken {
      accessToken
      expiresAt
    }
    customerUserErrors {
      code
      field
      message
    }
  }
}
`

const successLogin = ({ data }) => {
  const { customerAccessTokenCreate } = data;
  const { customerAccessToken } = customerAccessTokenCreate;
  const { accessToken, expiresAt } = customerAccessToken;
  localStorage.setItem("userTokenValue", accessToken);
  localStorage.setItem("userTokenExpiration", expiresAt);
  return {
    type: LOGIN_OK,
    payload: {
      token: accessToken,
      expiration: new Date(expiresAt)
    }
  };
}

export const loginCmd = ({ email, password }) => {
  return Cmd.run(
    () => getGraphQLClient().mutate({ mutation: CUSTOMER_LOGIN, variables: { input: { email, password } } }),
    {
      successActionCreator: successLogin,
      failActionCreator: (result) => ({ type: "ERROR" }),
    }
  )
}

export const login = ({ email, password }) => {
  return {
    type: LOGIN_REQUEST,
    payload: {
      email,
      password
    }
  }
}

////// Register

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_OK = "REGISTER_OK";

const CUSTOMER_REGISTER = gql`
  mutation customerCreate($input: CustomerCreateInput!) {
    customerCreate(input: $input) {
      customer {
        id
      }
      customerUserErrors {
        code
        field
        message
      }
    }
  }
`

const registerSuccess = () => ({ type: REGISTER_OK });

export const registerCmd = ({ email, password, firstName, lastName }) => {
  return Cmd.run(
    () => getGraphQLClient().mutate({ mutation: CUSTOMER_REGISTER, variables: { input: { email, password, firstName, lastName } } }),
    {
      successActionCreator: registerSuccess,
      failActionCreator: (result) => ({ type: "ERROR" }),
    }
  )
}

export const register = ({ email, password, firstName, lastName }) => ({
  type: REGISTER_REQUEST,
  payload: { email, password, firstName, lastName }
});

/// REDIRECT

const REDIRECT = "REDIRECT";
export const redirectCmd = to => {
  return Cmd.run(
    () => navigate(to),
    {
      successActionCreator: () => ({ type: REDIRECT }),
      failActionCreator: (data) => ({ type: "ERROR REDIRECT", data })
    }
  )
}

//// CHECKOUT INIT

export const INIT_CHECKOUT = "INIT_CHECKOUT";
export const INIT_CHECKOUT_KO = "INIT_CHECKOUT_KO";

const failInitCheckout = () => {
  localStorage.removeItem("checkoutId");
  return ({ type: INIT_CHECKOUT_KO })
};

const successInitCheckout = (checkout) => {
  if (!checkout || checkout.completedAt) {
    return failInitCheckout();
  } else {
    localStorage.setItem("checkoutId", checkout.id);
    return { type: INIT_CHECKOUT, payload: checkout };
  }
}

export const initCheckoutCmd = (client) => {
  const checkoutId = localStorage.getItem("checkoutId");
  /// TODO Add more verif ?
  if (!checkoutId) {
    return Cmd.run(
      () => client.checkout.create(),
      {
        successActionCreator: successInitCheckout,
        failActionCreator: () => ({ type: "ERROR CHECKOUT CREATION" }),
      }
    )
  }
  return Cmd.run(
    () => client.checkout.fetch(checkoutId),
    {
      successActionCreator: successInitCheckout,
      failActionCreator: failInitCheckout,
    }
  )
};


//// ADD TO CART

export const ADD_TO_CHECKOUT_REQUEST = "ADD_TO_CHECKOUT_REQUEST";
export const ADD_TO_CHECKOUT_OK = "ADD_TO_CHECKOUT_OK";
export const ADD_TO_CHECKOUT_KO = "ADD_TO_CHECKOUT_KO";

export const addToCheckoutCmd = (client, checkout, { variant, quantity }) => {
  if (!checkout) {
    return ({ type: "ERROR ERROR CHECKOUT SHOULD EXISTS" });
  }

  return Cmd.run(
    () => client.checkout.addLineItems(checkout.id, [{ variantId: variant, quantity }]),
    {
      successActionCreator: (checkout) => ({ type: ADD_TO_CHECKOUT_OK, payload: checkout }),
      failActionCreator: () => ({ type: ADD_TO_CHECKOUT_KO })
    }
  )
}

export const addToCheckout = ({ variant, quantity }) => ({
  type: ADD_TO_CHECKOUT_REQUEST,
  payload: {
    variant,
    quantity,
  }
});

/// Associate user to checkout 

const CHECKOUT_ASSOCIATION_OK = "CHECKOUT_ASSOCIATION_OK";

const ASSOCIATE_CUSTOMER_TO_CHECKOUT = gql`
  mutation associateCustomerWithCheckout($checkoutId: ID!, $customerAccessToken: String!) {
    checkoutCustomerAssociateV2(checkoutId: $checkoutId, customerAccessToken: $customerAccessToken) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
      customer {
        id
      }
    }
  }
`

export const associateCheckoutToUserCmd = (checkout, userAccesstoken) => {
  if (!checkout || !userAccesstoken) {
    return Cmd.none;
  }

  return Cmd.run(
    () => getGraphQLClient().mutate({ mutation: ASSOCIATE_CUSTOMER_TO_CHECKOUT, variables: { checkoutId: checkout.id, customerAccessToken: userAccesstoken } }),
    {
      successActionCreator: (res) => ({ type: CHECKOUT_ASSOCIATION_OK, payload: res.data.checkoutCustomerAssociateV2.checkout }),
      failActionCreator: (res) => ({ type: "ASSOCIATION_ERROR", payload: res })
    }
  )
}

//// Check Availability
export const CHECK_AVAILABILITY = "CHECK_AVAILABILITY";
export const CHECK_AVAILABILITY_OK = "CHECK_AVAILABILITY_OK";

const checkAvailabilitySuccess = result => {
  return {
    type: CHECK_AVAILABILITY_OK,
    payload: {
      productId: result.id,
      isAvailable: result.variants.reduce((res, { id, available }) => ({ ...res, [id]: available }), {})
    }
  }
}

export const checkAvailabilityCmd = (client, productId) => {
  return Cmd.run(
    () => client.product.fetch(productId),
    {
      successActionCreator: checkAvailabilitySuccess,
      failActionCreator: result => ({ type: "CHECK_AVAILABILITY_ERROR", payload: result })
    }
  )
}

export const checkAvailability = (productId) => {
  return {
    type: CHECK_AVAILABILITY,
    payload: productId,
  }
}

/// DELETE CHECKOUT ENTRY

export const REMOVE_FROM_CHECKOUT_REQUEST = "REMOVE_FROM_CHECKOUT_REQUEST";
export const REMOVE_FROM_CHECKOUT_REQUEST_OK = "REMOVE_FROM_CHECKOUT_REQUEST_OK";

export const removeFromCheckoutCmd = (client, checkout, shopifyId) => {
  if (!checkout) {
    return ({ type: "ERROR ERROR CHECKOUT SHOULD EXISTS" });
  }

  return Cmd.run(
    () => client.checkout.removeLineItems(checkout.id, [shopifyId]),
    {
      successActionCreator: (checkout) => ({ type: REMOVE_FROM_CHECKOUT_REQUEST_OK, payload: checkout }),
      failActionCreator: (err) => ({ type: "REMOVE_ERROR", payload: err })
    }
  )
}

export const removeFromCheckout = shopifyId => ({
  type: REMOVE_FROM_CHECKOUT_REQUEST,
  payload: shopifyId,
});