import { loop, Cmd } from 'redux-loop';
import { 
  INIT, 
  initShopifyClientCmd, 
  INIT_SHOPIFY_CLIENT, 
  INIT_USER, 
  initUserCmd,
  GET_USER_SUCCESS,
  getUserCmd,
  loginCmd,
  LOGIN_OK,
  LOGIN_REQUEST,
  REGISTER_REQUEST,
  registerCmd,
  REGISTER_OK,
  redirectCmd,
  CREATE_CHECKOUT_REQUEST,
  createCheckoutCmd,
  initCheckoutCmd,
  INIT_CHECKOUT,
  ADD_TO_CHECKOUT_REQUEST,
  addToCheckoutCmd,
  ADD_TO_CHECKOUT_OK,
  associateCheckoutToUserCmd,
  CHECKOUT_ASSOCIATION_OK,
  INIT_CHECKOUT_KO,
  CHECK_AVAILABILITY,
  checkAvailabilityCmd,
  CHECK_AVAILABILITY_OK,
  REMOVE_FROM_CHECKOUT_REQUEST,
  removeFromCheckoutCmd,
  REMOVE_FROM_CHECKOUT_REQUEST_OK,
  ADD_TO_CHECKOUT_KO,
} from './actions';

export const initialState = {
  client: undefined,
  userToken: {
    token: undefined,
    expiration: undefined,
  },
  user: {
    meta: undefined,
    addresses : [],
    orders: [],
    defaultAddress : undefined,
  },
  checkout: undefined,
  products : {},
  loading: {
    adding: false,
  }
}

export const reducer = (state = initialState, action) => {
  const {type, payload} = action;
  switch(type) {
  case INIT:
    return loop(state, Cmd.list([
      initShopifyClientCmd(),
      initUserCmd(),
    ]));
  case INIT_SHOPIFY_CLIENT:
    return loop({...state, client: payload}, initCheckoutCmd(payload));
  case INIT_CHECKOUT_KO:
    return loop(state, initShopifyClientCmd());

  case LOGIN_OK:
  case INIT_USER:
    return loop({...state, userToken: payload}, getUserCmd(payload.token));

  case GET_USER_SUCCESS:
    return loop({...state, user: payload}, associateCheckoutToUserCmd(state.checkout, state.userToken.token));

  case LOGIN_REQUEST:
    return loop(state, loginCmd({
      email: payload.email, 
      password: payload.password
    }));

  case REGISTER_REQUEST:
    return loop(state, registerCmd({
      email: payload.email,
      password: payload.password,
      firstName: payload.firstName,
      lastName: payload.lastName,
    }));
  case REGISTER_OK:
    return loop(state, redirectCmd("/account/login"));

  case CREATE_CHECKOUT_REQUEST:
    return loop(state, createCheckoutCmd(state.client, !!state.checkout));
  

  case INIT_CHECKOUT:
    return loop({...state, checkout: payload}, associateCheckoutToUserCmd(payload, state.userToken.token));
  case CHECKOUT_ASSOCIATION_OK:
  case REMOVE_FROM_CHECKOUT_REQUEST_OK:
    return {...state, checkout: payload};
  case ADD_TO_CHECKOUT_OK:
    return {...state, checkout: payload, loading : {...state.loading, adding: false}};

  case ADD_TO_CHECKOUT_REQUEST: {
    const next_state = {...state, loading: {...state.loading, adding: true}};
    return loop(next_state, addToCheckoutCmd(state.client, state.checkout, payload));
  }

  // Manage error ?
  case ADD_TO_CHECKOUT_KO : {
    return {...state, loading: {...state.loading, adding: false}};
  }

  case CHECK_AVAILABILITY:
    return loop(state, checkAvailabilityCmd(state.client, payload));

  case CHECK_AVAILABILITY_OK:{
    const next_products = {...state.products, [payload.productId]: {isAvailable: payload.isAvailable}}
    return {...state, products: next_products};
  }

  case REMOVE_FROM_CHECKOUT_REQUEST:
    return loop(state, removeFromCheckoutCmd(state.client, state.checkout, payload))

  default:
    return state;
  }
}